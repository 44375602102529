<template>
  <div class="bind">
    <TopBar title="绑定学号">
      <BackIcon></BackIcon>
    </TopBar>
    <v-container class="px-5">
      <TopInfo :title="title" :img="imgPath"></TopInfo>
      <v-text-field
        label="学号"
        value=""
        v-model="username"
        color="blue-gray"
        messages="请输入智慧校园的学号"
      ></v-text-field>
      <v-text-field
        label="密码"
        :type="inputType"
        v-model="password"
        color="blue-gray"
        messages="请输入智慧校园的密码"
      >
      <template v-slot:append><v-icon v-text="iconEye" @click="change"></v-icon></template>
      </v-text-field>
      <p class="caption grey--text text--darken-1 mt-1">绑定前请先阅读并同意
        <span class="primary--text" @click="toPrivacy">《Na+课表隐私协议》</span>
      </p>
      <v-btn block elevation="0" outlined height="40" class="mt-4" @click="handerBindStu" :loading="loading">立即绑定</v-btn>
    </v-container>
  </div>
</template>

<script>
import { setChildViewMixin, eyeChangeMixin } from "common/mixin"
import { bindStu } from 'network/user'
import { mapActions } from 'vuex'
import bind from "assets/img/today/bind.svg"
import bindDark from "assets/img/today/bindDark.svg"
export default {
  name: "Bind",
  mixins: [setChildViewMixin, eyeChangeMixin],
  data: () => ({
    title: "绑定/修改学号信息",
    username: '',
    password: '',
    inputType: 'password',
    loading: false
  }),
  mounted() {
    const username = this.$store.state.userInfo.sch_username
    if (username) {
      this.username = username
    }
  },
  methods: {
    ...mapActions(['getUserInfo']),
    handerBindStu() {
      if(this.username && this.password) {
        this.loading = true
        this.reqBindStu()
      } else {
        this.$notify({
          title: '绑定提示',
          message: '请输入学号和密码',
          type: 'warning',
          duration: 1500,
        })
      }
    },
    reqBindStu() {
      bindStu(this.username, this.password)
      .then(res => {
        this.loading = false
        this.$notify({
          title: '学号信息绑定成功',
          type: 'success',
          duration: 1500,
        })
      })
      .then(() => {
        this.getUserInfo()
      })
    },
    toPrivacy() {
      this.$router.push('/today/privacy')
    }
  },
  computed: {
    imgPath() {
      return this.isDark ? bindDark : bind
    },
  },
}
</script>

<style>
</style>